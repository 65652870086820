import { useEffect } from "react";

import Router from "next/router";

import Loading from "~/components/Loading";
import useCurrentViewer from "~/hooks/useCurrentViewer";
import useCurrentViewerVaspDID from "~/hooks/useCurrentViewerVaspDID";
import useVaspContext from "~/hooks/useVaspContext";
import useAuth from "~/utils/useAuth";

const Auth = () => {
  const { user, isNotabeneAdmin, isLoading: isLoadingAuth } = useAuth();
  const { viewer, isLoading: isLoadingViewer, online } = useCurrentViewer();
  const { vasps, isLoading: isLoadingVasps } = useVaspContext();
  const currentVaspDID = useCurrentViewerVaspDID();

  const isLoading = isLoadingAuth || isLoadingViewer || isLoadingVasps;

  useEffect(() => {
    if (isLoading) return;

    (async () => {
      if (!online) {
        return <Loading isOnline={online} />;
      }

      if (!user || viewer.did === "noUser") {
        localStorage.removeItem("activeVasp");
        return Router.replace(`/auth/get-started`);
      }

      // If the user is a Notabene admin then just take them
      // directly to the dashboard
      if (isNotabeneAdmin) {
        return Router.replace("/dashboard/companies");
      }

      // If the user has not filled in their personal info then take
      // them to the personal info onboarding step
      if (!viewer.fields.some((field) => field.fieldName === "name")) {
        return Router.replace("/auth/info");
      }

      const wasRedirectedToCreateNewVasp = Boolean(sessionStorage.getItem("disableMultiVaspSigninRedirect"));
      // If the user doesn't have a VASP and there's one available then
      // show the multi-vasp sign in page
      if (
        !wasRedirectedToCreateNewVasp &&
        (!currentVaspDID || !(currentVaspDID in vasps.loginable)) &&
        (vasps.claimable.length > 0 || vasps.joinable.length > 0 || vasps.loginable.length > 0)
      ) {
        return Router.replace("/auth/multivasp-signin");
      }

      // If the user has no VASPs that they're a user of and there's
      // none that they can claim/login/join then take them to register
      if (vasps.claimable.length === 0 && vasps.joinable.length === 0 && vasps.loginable.length === 0) {
        return Router.replace(`/onboarding/provider-search`);
      }

      return Router.replace("/dashboard/outgoing");
    })();
  }, [online, user, viewer, currentVaspDID, isNotabeneAdmin, vasps, isLoading]);

  return <Loading isOnline={online} />;
};

export default Auth;
